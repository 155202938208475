import React from 'react';
import Layout from '../components/layout';
import Banner from '../components/banner';
import Seo from '../components/seo'
import Footer from '../components/footer';
import styled from '@emotion/styled';
import useJobs from '../hooks/useJobs';
import usePhones from '../hooks/usePhones';
import useCategories from '../hooks/useCategories';
import Htmltext from '../components/htmlText';



const ProfilesContent = styled.ul`
    max-width: 1200px;
    width: 95%;
    margin: 4rem auto 2rem auto;

    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
        column-gap: 2rem;
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const Card = styled.div`
    border: 1px solid #E1E1E1;
    background-color: #6264A3;
   
`;

const DetailContent = styled.div`
    padding: 2rem;
     h5{
        font-family: 'Lato', sans-serif;
        margin: 0 0 2rem 0;
        font-size: 2.4rem;
        color: #ffcccc;  
    }
    p{
        font-size: 1.2rem;
        color: #ffffaa;    
        span {
            font-weight: bold;
            color: #ffdddd;
        }
        a{
            color: #0000bb
        }
    } 
`;

const Jobs = () => {
    const jobsquery = useJobs()
const phones = usePhones();
const categories = useCategories();
const jobs=jobsquery.allStrapiEntityJobsPages.nodes
const cardsjobs=jobsquery.allStrapiEntityCardsJobs.nodes
console.log(cardsjobs);

return ( 
    <Layout>
        <Seo 
            title={jobs[0].en_seo_title}
            keywords={jobs[0].en_seo_keywords}
            description={jobs[0].en_seo_description}
        />
        <Banner 
            banner_h1={jobs[0].en_banner_h1}
            banner_h2={jobs[0].en_banner_h2}
            phones={phones[0].phones}
        />
        <main>
            
            <Htmltext text={jobs[0].start_text_en}/>
           
            <ProfilesContent>
            {
    cardsjobs.map(cards => (
        <Card key={cards.id}> {/* Assuming each card has a unique ID */}
            <DetailContent>
                <h5>{cards.title_en}</h5>
                <p><span>{cards.subtitle_en}</span></p>
                <p>{cards.description_en}</p>
                <p><span>{new Date(cards.date).toLocaleDateString()} - <a href={cards.link} target="_blank" rel="nofollow">Go to the article</a></span></p>
            </DetailContent>
        </Card>
    ))
}                    
            </ProfilesContent>

            <Htmltext text={jobs[0].end_text_en}/>
        </main>
        <Footer 
            h3_section={jobs[0].en_category_h3_section}
            p_section={jobs[0].en_category_p_section}
            categories={categories}
            lan='en'
        />
    </Layout>
 );
}


export default Jobs;