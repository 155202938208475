import { useStaticQuery, graphql } from 'gatsby';

const useJobs = () => {

    const pages = useStaticQuery( graphql `
   
    {
      allStrapiEntityJobsPages {
        nodes {
          id
          end_text
          end_text_en
          en_seo_title
          en_seo_keywords
          en_seo_description
          en_category_p_section
          en_category_h3_section
          en_banner_h2
          en_banner_h1
          category_p_section
          category_h3_section
          banner_h2
          banner_h1
          start_text_en
          start_text
          seo_title
          seo_keywords
          seo_description
        }
      }
      allStrapiEntityCardsJobs {
        nodes {
          title
          title_en
          subtitle
          subtitle_en
          link
          description_en
          description
          date
        }
      }
    }
  
    `);

    return pages
}
 
export default useJobs;