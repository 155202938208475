import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Seo = ({description, keywords, title, image, url, author}) => {
    return ( 
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = description || data.site.siteMetadata.description
                const metaTitle = title || data.site.siteMetadata.title
                const metaAuthor = author || data.site.siteMetadata.author
                const metaUrl = url || data.site.siteMetadata.url
                const metaImage = image || data.site.siteMetadata.image
                const metaKeywords = keywords.split(',') || ["Acompañantes","Damas de compañía"]
                return (
                    <Helmet
                        title={title}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription
                            },
                            {
                                peoperty: `og:title`,
                                content: metaTitle
                            },
                            {
                                peoperty: `og:description`,
                                content: metaDescription
                            },
                            {
                                peoperty: `og:type`,
                                content: `website`
                            },
                            {
                                peoperty: `og:url`,
                                content: metaUrl
                            },
                            {
                                name: `twitter:card`,
                                content: `summary_large_image`
                            },
                            {
                                name: `twitter:creator`,
                                content: metaAuthor
                            },
                            {
                                name: `twitter:title`,
                                content: metaTitle
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription
                            },
                            {
                                name: `twitter:image`,
                                content: metaImage
                            },
                        ].concat(
                            metaKeywords && metaKeywords.length > 0 ? {
                                name: `keywords`,
                                content: metaKeywords.join(`,`),
                            } : []
                        )}
                    >
                        <link href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" rel="stylesheet" />
                        <meta name="robots" content="index, follow"></meta>  
                        <link rel="alternate" href="https://acompanantescolombia.com/" hreflang="es" />  
                        <link rel="alternate" href="https://acompanantescolombia.com/en/" hreflang="en" />

                    </Helmet>
                            
                )
            }} 
        />
     );
}
 
export default Seo;


const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                url
                image
            }
        }
    }
`;