import React from 'react'

const Htmltext =({text})=>{
  function createMarkup(text) {
    return { __html: text };
  }

return (
  <div style={{maxWidth: '1200px', width:'100%',margin:'auto', justifyContent: 'center', padding: '3rem' }}>
  
  <div style={{ width:'90%',margin:'auto', paddingTop: '0.5rem' }}>
    <p style={{ textAlign: 'justify', color: 'black' }} dangerouslySetInnerHTML={createMarkup(text)}>
    </p>
  </div>
</div>
)


}

export default Htmltext;